/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components

// core components
import DemoFooter from "components/Footers/DemoFooter.js";
import IndexNavbar from '../../components/Navbars/IndexNavbar';
import { Col, Container, Row } from 'reactstrap';
import ProfilePageHeader from '../../components/Headers/ProfilePageHeader';

function JakToByloPage() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <IndexNavbar />
            <ProfilePageHeader />
            <>
                <div className="section section-white">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center title" md="6">
                                <h1>Bydlík hledá nové majitele!</h1>
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Nabízíme k prodeji naši obytnou dodávku. Vestavba je dělaná na míru, námi, teprve rok stará. Je tedy v perfektním stavu. Auto prodáváme z důvodu <b>dlouhodobého odjezdu do zahraničí</b>. Cesty nás tentokrát vedou dál, než by Bydlík chtěl jet. A tak, než aby auto několik měsíců stálo na parkovišti, raději jej posíláme dál, ať dělá radost na českých a evropských cestách někomu dalšímu!</p>
                            <p className="text-justify font-weight-normal p-2"><b>Volkswagen Transporter T5 2.0 TDI 62kW</b> je rok výroby 2012, najeto má 250 tis. Pochází z řady, která měla potíže s motorizací, v létě 2020 byl motor i převodovka vyměněny. Oprava to byla za 120 tis., vše můžeme doložit fakturami.</p>
                            <p className="text-justify font-weight-normal p-2">Technicky je auto v dobrém stavu, ve vestavbě také vše funguje. Za zmínku stojí občasný cvakající zámek ve dveřích (šikovný autoelektrikář určitě po čase dovolených opraví :).</p>
                            <p className="text-justify font-weight-normal p-2">Auto má <b>3 místa k sezení</b>, klimatizaci, autorádio. Disponuje <b>nezávislým topením</b>, jako obytná dodávka tak poslouží i v zimě. Na rok 2021 je pro auto zakoupena <b>dálniční známka</b> pro ČR.</p>
                            <p className="text-justify font-weight-normal p-2"><b>Vestavba</b> je zapsaná <b>v technickém průkazu</b>, jedná se tak o vozidlo M1 zvláštního určení. Vestavba je tak zkontrolována i po technické stránce. Je však i plně vyjímatelná.</p>
                            <p className="text-justify font-weight-normal p-2">V zadní části vozu najdete <b>výsuvnou kuchyňskou linku</b> s dřezem a vodovodní baterií. Součástí linky jsou i 2 20l kanystry (jeden na pitnou vodu, druhý na odpadní). V lince je kompresorová <b>lednice - Mobicool FR34 31l</b>.</p>
                            <p className="text-justify font-weight-normal p-2">Pod <b>lůžkem 160 x 190 cm</b> se skrývá dostatek <b>úložného prostoru</b>. Pohodlí pro spánek zajišťuje kvalitní 14 cm vysoká <b>matrace</b> se snímatelnými pratelnými potahy. Lůžko lze jednoduše změnit na <b>lavici se stolem</b>. Pohodlně se usadí 3 - 4 lidé.</p>
                            <p className="text-justify font-weight-normal p-2">Po obvodu zadní části automobilu jsou <b>LED světla</b>. Dobíjení telefonů je možné jak vpředu ze zapalování tak vzadu u lůžka, součástí jsou <b>dvě USB zásuvky</b>. Běh zásuvek, světel, lednice a 12V baterie na vodu je zajištěn pomocí <b>nezávislé baterie GOOWEI ENERGY 75 Ah</b>, která se za jízdy sama dobíjí. V kempu je možné připojit ji připojit k elektřině. </p>
                            <p className="text-justify font-weight-normal p-2">Vůz prodáváme <b>s veškerým vybavením</b>. Na ledničku, baterii, nabíječku na baterku apod. je ještě min. 1 rok záruka. V dalším vybavením auta je: <b>kempingový stůl, kobercová plachta, tlaková solární sprcha, vařič, veškeré kempingové nádobí</b>.</p>
                            <p className="text-justify font-weight-normal p-2">Obytné auto jsme <b>půjčovali i ostatním cestovatelům</b>.  Z vlastní zkušenosti můžeme říci, že o <b>půjčení obytného auta je velký zájem</b>, lze se tak  auto podělit a vydělat na jeho provoz.</p>
                            <p className="text-justify font-weight-normal p-2">Fotografie z přestavby vozu i hotové vestavby zde na blogu.</p>
                            <p className="text-justify font-weight-normal p-2">Pro více informací, prohlídky vozu, dotazy apod. nás prosím kontaktujte na emailu <b>dominika.balatkova@gmail.com</b>.</p>
                        </Row>

                        {/*<br />*/}
                        {/*<hr />*/}
                        {/*<Row>*/}
                        {/*    <Media>*/}
                        {/*        <a*/}
                        {/*            className="pull-left"*/}
                        {/*            href="#dominika"*/}
                        {/*            onClick={(e) => e.preventDefault()}*/}
                        {/*        >*/}
                        {/*            <div className="avatar big-avatar">*/}
                        {/*                <Media*/}
                        {/*                    alt="..."*/}
                        {/*                    object*/}
                        {/*                    src={require("assets/img/dominika.jpg")}*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*        </a>*/}
                        {/*        <Media body>*/}
                        {/*            <Media heading>Dominika</Media>*/}
                        {/*            <h6 className="text-muted">7.2.2021</h6>*/}
                        {/*        </Media>*/}
                        {/*    </Media>*/}
                        {/*</Row>*/}
                    </Container>
                </div>
            </>
            <DemoFooter />
        </>
    );
}

export default JakToByloPage;
