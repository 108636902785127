import React from "react";

import DemoFooter from "components/Footers/DemoFooter.js";
import IndexNavbar from '../../components/Navbars/IndexNavbar';
import { Col, Container, Row } from 'reactstrap';
import ArticleHeader from './ArticleHeader';

function IstriePage() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <IndexNavbar />
            <ArticleHeader backgroundImage={"url(" + require("assets/img/istrie/cover.jpeg") + ")"} />
            <>
                <div className="section section-white">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center title" md="6">
                                <h1>Toulky po Istrii</h1>
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Koncem října nám do klína spadla další možnost vyrazit do světa. Zpomalení zemských otáček nám oběma přineslo home office a vyhlídku do dalších šedivých a deštivých pražských dnů. Naložili jsme s tím po svém, sbalili sebe i Bydlíka a vyrazili na cestu po chorvatské Istrii. Následující řádky vás provedou nejkrásnějšími místy, které jsme za tři týdny potulky objevily. Nechť vám jsou inspirací, až pojedete na chorvatský sever. </p>
                        </Row>
                        <Row>
                            <h2>Savudija, Lighthouse</h2>
                        </Row>
                        <Row>
                            <Col className="mr-auto ml-auto p-3" md="12">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/majak.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Malebné místo na poloostrově Umag, kde jsme se rozhodli přespat hned, jak přejedeme hranice se Slovinskem. Kousek od hranic, jedete-li od Piraně, se nabízí kemp se stejným jménem. Je určen pro obytňáky a je fungl nový. Najdete zde krásné nové sprchy, zázemí pro karavany a recepci, kterou, v případě nepřítomnosti majitelů, můžete využít jako self check-in. Kemp se nachází hned vedle dosud funkčního majáku. Za majákem je kempů více, a tak tu možná během sezóny bude plno, my tu však byli, nepočítaje tři místní rybáře, sami.</p>
                        </Row>
                        <Row>
                            <Col className="mr-auto ml-auto p-3" md="10">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/kemp-majak.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Západ a východ slunce u majáku nás natolik nadchnul, že jsme se sem po cestě zpátky ještě vraceli. Výhledy na slunce topící se v blankytném moři, oranžová zář a velkolepý obrys majáku nedají spát nejednomu fotografovi.</p>
                        </Row>
                        <Row>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/zapad2.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Kousek za majákem je bar Havana s krásnou terasou nad mořem. Strávili jsme v něm pěkný večer ve společnosti drinků a místní hudby. Reklamu mu zde udělat trochu musíme, dlužíme tam totiž za pár drinků. Až pozdě v noci se ukázalo, že v baru nefunguje terminál pro platební karty, mladé majitele však nedostatek naší hotovosti nerozhodil a s úsměvem nám popřáli krásnou noc a ať prý při další návštěvě Umagu přijdeme znovu. Tak do Havany zajděte, západ slunce z tamní terasy a milá obsluha za to určitě stojí.</p>
                        </Row>
                        <Row>
                            <h2>Park Vranjska Draga, Aurania</h2>
                        </Row>
                        <Row>
                            <Col className="mr-auto ml-auto p-3" md="12">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/draga.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Vinnetoua indiána známe asi všichni. Málokdo ale ví, že krom Plitvických jezer se velká část jeho dobrodružství točila v samém srdci Istrie. V národním parku Vranjska Draga. Park je protkán cestami, které vás zavedou k úchvatným výhledům do kaňonu Vela Draga. Přímo do kaňonu se můžete dostat dvěma cestami - ta první vede po kolejích, na kterých už ale moc vlaků nepotkáte. Vyjít je třeba ze stanice vlaků ve městečku Lupoglav, sedmi kilometrová trasa pak vede krásnou krajinou. Nezapomeňte si čelovku, kaňon Vela Draga totiž přetíná půlkilometrový tunel, v němž je naprostá tma.</p>
                            <p className="text-justify font-weight-normal p-2">Druhou možností, jak se do kanoňu dostat, je zeshora, právě z národního parku Vranjska Draga. Kousek od benzinové stanice INA Učka najdete malé parkoviště, kde ponechejte auto a vydejte se pěšky po značené stezce. Za moment budete stát na rozcestí, jehož jediným pokračováním je sráz dolů. Buďte opatrní, při troše fyzické kondice a v dobrých botách ale dolů slezete. Cesta dolů vám zabere kolem půl hodiny, nahoru pak počítejte možná trochu méně, zato s propocenějším tričkem :)</p>
                        </Row>
                        <Row>
                            <Col className="ml-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/tunel1.jpeg")}
                                />
                            </Col>
                            <Col className="mr-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/kanon.jpeg")}
                                />
                            </Col>
                            <Col className="mr-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/vinetue.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Do srdce kaňonu ústí už zmíněný půlkilometrový tunel. Když budete mít štěstí, vaše svačina přiláká pár kamzíků a dalších horských koz, co si ale určitě užijete, je malebný výhled. Tak trochu jinak, protože tahle vyhlídka je na kopce nahoru. Hledat můžete i scenérie přímo z Vinnetoua tak jako my. </p>
                        </Row>
                        <Row>
                            <h2>Vojak - nejkrásnější výhled z Istrie</h2>
                        </Row>
                        <Row>
                            <Col className="mr-auto ml-auto p-3" md="12">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/vojak-vyhled.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Směrem na západ od Vely Dragy se rozprostírá pohoří Učka. A jeho nejvyšším vrcholem je Vojak. Vedou na něj dvě cesty: jedna rovně nahoru lesem, druhá pak kolem po silnici. Záleží na vás, kterou si vyberete. My jsme se na vrcholu omylem ocitli i s Bydlíkem. Jak jsme později zjistili, vjezd nahoru je zakázán, neboť jde o vojenskou oblast a oblast národního parku zároveň. Pravda, trochu se za naši nepozornost stydíme dodnes, ale za ty výhledy zhora jsme rádi. Zajímavou stavbou je i rozhledna samotná. Zaujala nás i rozjezdová paraglidingová dráha, kdo tak holdujete tomuto adrenalinovému vyžití, jistě se ptejte po možnosti letět přímo z vrcholu. Pohled do dálek je magický, ostrůvky v dálce tvoří nádherné scenérie a nebe má snad tisíc barev. </p>
                        </Row>
                        <Row className="text-center p-4">
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/vojak1.jpeg")}
                                />
                            </Col>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/vojak4.jpeg")}
                                />
                            </Col>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/vojak3.jpeg")}
                                />
                            </Col>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/vojak2.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <h2>Arena Stoja - místo pro campery</h2>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Pokud vyrážíte obytným autem jako my, určitě nesmíte vynechat kemp Arena Stoja. Samotný kemp je srovnatelný s těmi ostatními na istrijském pobřeží, ale jen v tomto kempu můžete auto zaparkovat na útesu a usínat tak za zvuku mořského příboje. A západy slunce máte z první řady. Cena za takovéto místo se mimo sezónu pohybovala kolem 30 euro, v sezóně počítejte s částkou dvakrát tak vyšší. I tak to za to ale minimálně na dvě noci stojí. Ono, co si budeme, pokud si s sebou nevezete kola nebo nechcete každý den jezdit autem desítky kilometrů, ani déle v kempu nepobudete. Kolem toho totiž moc není a koupání se také dá najít lepší. Pokud ovšem místo bílých oblázků nepreferujete členité útesy.</p>
                        </Row>
                        <Row className="text-center p-4">
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/stoja1.jpeg")}
                                />
                            </Col>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/stoja2.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/stoja0.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Blízko kempu Arena Stoja se nachází i hlavní metropole celé Istrie - město Pula. Zřejmě ji nemusíme dlouze představovat, za návštěvu stojí ve dne i v noci, kdy vás mohou zaujmout zajímavě nasvícené jeřáby, jež už několik let opravují stavby v přístavu, i jedna z nejznámějších památek celé Istrie, totiž římský amfiteátr Pula Arena, jež je stejně starou a neméně krásnou sestrou Římského kolosea.</p>
                        </Row>
                        <Row className="text-center p-4">
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/pula2.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <h2>Po stopách dinosaurů</h2>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">I dinosaury jsme na Istrii našli! Na poloostrově Kamenjak se nachází národní park Premantura. Právě zde byla údajně nalezena stopa dinosaura. Kdo hledá, najde ji. Dnes park slouží především jako oáza klidu, pěkných pláží a dlouhých a udržovaných vycházkových cest. Sem tam na vás vykoukne zpoza větví dinosaurus, neboť Prematura je jedním velkým dinoparkem pod širým nebem. Vstupné se tu ale neplatí, a tak může být vycházka na tento téměř zapomenutý cíp Istrie příjemným zpestřením i vašich toulek. My jsme klid a krásné scenérie využili hlavně k létaní s dronem.</p>
                        </Row>
                        <Row className="text-center p-4">
                            <Col className="mr-auto ml-auto p-1" md="12">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/prematura.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <h2>Poreč - nejkrásnější město Istrie</h2>
                        </Row>
                        <Row className="text-center p-4">
                            <Col className="mr-auto ml-auto p-1" md="10">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/porec0.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Cestou zpět na sever Istrie jsme se rozhodli zastavit se v Poreči. I vám tuto zastávku můžeme jen doporučit - slavnostně jsme ji vyhlásili nejkrásnějším městem Istrie. Tady fotografie mluví za vše. Poreč je jako stvořená k procházkám podél pobřeží - po zelené stezce kolem moře můžete dojít daleko za město, za zády pak celou dobu budete mít nádherné výhledy na přístav s ikonickou věží místního kostela a po své pravé ruce mnoho malých plážiček, které lákají ke slunění.</p>
                        </Row>
                        <Row className="text-center p-4">
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/porec-ulice1.jpeg")}
                                />
                            </Col>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/porec-ulice2.jpeg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">V Poreči máme také jeden tip pro spokojené bříško - až půjdete starým městem, zastavte se v restauraci Peterokutna kula. Podnik najdete ve vršku pětiúhelníkové věže z 15. století. Uvnitř je restaurace stylově zasazena do několika podlaží věže, na jejím vršku pak najdete venkovní zahrádku s nádherným výhledem. Při západu slunce je to místo přímo magické a jídlo tu mají opravdu výborné. Vědí to i místní racci, tak pozor na ně, ať vám večeře neulétne před nosem.</p>
                        </Row>
                        <Row className="text-center p-4" >
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/porec-restaurace1.jpeg")}
                                />
                            </Col>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/istrie/porec-restaurace2.jpeg")}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
            <DemoFooter />
        </>
    );
}

export default IstriePage;
