/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components

// core components
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import IndexNavbar from '../../components/Navbars/IndexNavbar';
import { Col, Container, Row } from 'reactstrap';

function JakToByloPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
        <>
            <div className="section section-white">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center title" md="6">
                            <h1>Bydlíkův příběh</h1>
                            <h3 className="title-uppercase">
                                <small>Jak to celé bylo</small>
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <p className="text-justify font-weight-normal p-2">Na jaře 2020 přišla do Čech pandemie koronaviru. Nejenom s cestováním byl na
                            nějakou dobu utrum. Plánů jsme na ten rok měli opravdu mnoho, hlavně těch
                            cestovatelských. Ty se ale nyní zdály v nedohlednu.</p>
                        <p className="text-justify font-weight-normal p-2">
                            Pořídit si obytnou dodávku byl momentální nápad. Takový, který přijde, ani
                            nevíte přesně kdy a už vůbec netušíte odkud. V době pandemie nebylo možné se
                            nikde ubytovat, nefungovaly pořádně ani restaurace a další služby, které na
                            cestách každý z nás využívá. Země za oceánem se většinou uzavřely úplně a
                            naše cestovatelské vyhlídky nedohlédly dál než na hranice Evropy. A právě po
                            Evropě bude prima být soběstačný a jet si kdykoliv kamkoliv nás napadne,
                            řekli jsme si. Dodávku koupíme, přestavíme, vyrazíme na pár výletů a až nás
                            přepadne touha odjet na pár měsíců zase někam za oceán, pošleme ji do světa.
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            Vypadalo to snadno. Alespoň podle youtube tutoriálů. Až překvapeni jsme
                            byli, kolik lidí mělo nápad si dodávku koupit, přestavit a natočit o tom
                            vlog na youtube. A při přestavbě jsme gratulovali všem těmto nadšencům, že
                            ještě měli nervy celou tuhle práci dokumentovat. To už bychom opravdu
                            nedali. Děkujeme vám všem, neúnavní bydlíkovlogeři! Bez vás bychom totiž
                            doteď neměli ani páru, že tohle jde zvládnout.
                        </p>
                    </Row>
                    <Row>
                        <h2>Koupě dodávky, zn. snadno a rychle</h2>
                    </Row>
                    <Row>
                        <p className="text-justify font-weight-normal p-2">Stejně rychle jako přišel nápad, proběhla i koupě. To, že to nebyl jeden z
                            našich nejlepších nápadů, to už dávno víme. Jenže kdybychom tenhle momentální nápad
                            nezrealizovali hned, asi bychom to neudělali nikdy. Důvodů, proč tak rychle,
                            bylo několik. Jeden za všechny: je jaro a jet chceme v létě - času prostě
                            není nazbyt!</p>
                        <p className="text-justify font-weight-normal p-2">
                            Koupili jsme tedy přesně třetí auto, na které jsme se byli podívat. Celou
                            koupi jsme zrealizovali třetí týden po prvním vyslovení nápadu. A dali se do
                            toho. Tedy, až potom, co jsme auto nechali zkontrolovat hned ve dvou
                            servisech. Dobrý kup! Plácali se automechanici po ramenou. Ano, páni
                            automechanici! Byl to dobrý kup, to nám Bydlík ukázal hned po tom, co jsme
                            přestavbu dokončili. Ale o tom až za chvíli.
                        </p>
                    </Row>
                    <Row>
                        <h2>Přestavba podle vlastních představ</h2>
                    </Row>
                    <Row>
                        <p className="text-justify font-weight-normal p-2">Naplánovat interiér nám mnoho času nezabralo. Z minulosti jsme ze zapůjčeného
                            víkendového obytňáčku měli zkušenost, že výsuvná kuchyň ze zadního kufru pro
                            nás není ideální řešení a od toho jsme se při plánování odpíchli. Místo
                            vzadu, kde mají osobáčky kufr, jsme chtěli zachovat. Myšlenka, že tam
                            vznikne takový bordelšuplík, kam odložíte zablácené boty, mokrou plachtu a
                            deku od písku, se ukázala jako velmi praktická.</p>
                        <p className="text-justify font-weight-normal p-2">
                            Kuchyň tak byla odsunuta dopředu a, protože Bydlík má jen metr a půl od
                            podlahy, i vysunuta z auta. Nebojte se, zasunout samozřejmě jde a při jízdě
                            se ani nehne! Pro ležení, sezení a stolek nám byla inspirací jedna již
                            dlouho vyráběná kempingová vestavba, kterou si můžete pořídit samostatně do
                            vlastního camperu. Ta nám ale přišla velmi drahá a taky se nám kovové
                            provedení příliš nelíbilo. Jako inspirace, jak lze ve vanu uspořádat
                            prostor, ale posloužila dokonale. Děkujeme, Visu!
                        </p>
                    </Row>
                    <Row>
                        <h2>Kam pro šikovné ručičky</h2>
                    </Row>
                    <Row>
                        <p className="text-justify font-weight-normal p-2">
                            Za celých 11 let, co jsme spolu, jsme procestovali spoustu míst, avšak co se
                            stavebních prací týče, zvládli jsme sestavit přesně tři skříně z Ikea,
                            přišroubovat dvě poličky a vymalovat jeden celý byt. A tak, to ještě před
                            koupí auta, proběhla mezi námi krátká diskuze, kdože nám s tím pomůže a kde
                            vlastně tu přestavbu budeme jako dělat. Bydlíme totiž v Praze, ve 2kk v
                            Libni.
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            Naštěstí ale máme, v tomto případě Domčiny, rodiče. Ti, kteří s námi měli
                            vždycky tolika trpělivosti, nyní museli ještě trochu vyšťourat ze svých,
                            jistě bezedných zásob. Nadšeného tatínka bychom přáli všem, kteří se někdy
                            do vestavby pustí. Nejenom, že okamžitě věděl, z čeho to bude a jak se to
                            udělá, ale také byl ochoten po šest týdnů nedělat vlastně skoro nic jiného.
                            A maminka zase musela snášet naše nadávání, že tohle nejde, a tamto tam zase
                            nepasuje a už zase musíme do hobbymarketu a taky, taky nás musela krmit.
                            Protože vařit už jsme k přestavbě prostě nestíhali. Nakonec se právě tahle
                            maminka ukázala jako nejzdatnější konstruktérka - vymyslela nejenom panty na
                            otevírání naší skříňopostele, ale také celý výsuvný systém kuchyně. Bravo,
                            mami! Děkujeme, tati!
                        </p>
                    </Row>
                    <Row>
                        <h2>Trocha těch technikálií aneb deset fází přestavby</h2>
                    </Row>
                    <Row>
                        <p className="text-justify font-weight-normal p-2">Následující řádky snad uspokojí ty z vás, kteří jsou tu, aby získali dobré
                            rady pro přestavbu vlastní dodávky. Takže tedy:</p>
                        <ul className="font-weight-normal">
                            <li>
                                hobbymarket začněte považovat za svůj druhý domov
                            </li>
                            <li>
                                připravte si o něco víc peněz, než počítáte
                            </li>
                            <li>
                                příliš neplánujte - dobré nápady přichází samy, když už jste zoufalí a
                                tedy, když je nejvíce potřebujete
                            </li>
                        </ul>
                        <p className="text-justify font-weight-normal p-2">A hlavně si proboha nemyslete, že to bude tak jednoduché jako na tom
                            youtubu!</p>
                        <p className="text-justify font-weight-normal p-2">
                            Teď vážně. Přestavbu jsme se rozhodli realizovat svépomocí. Měli jsme k
                            dispozici zkušeného tatínka, který přestavil vlastními rukama dům. Dílnu,
                            která byla vybavena tak akorát - přímočarka, šroubováky, kleště, metr,
                            úhloměr, aku vrtačka, a to nejdůležitější - několik pořádně ostrých
                            kobercáků. Ne, neděláme si srandu. Tyhle malé nože byly to nejpoužívanější
                            náčiní vůbec.
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            <b>První fáze</b> - nákup. Hobbymarket nám poskytl vše, co jsme pro první krok
                            potřebovali - laťky, OSB desku, spoustu minerální vaty, kabely pro rozvody
                            elektřiny a několik na míru nařezaných spárových desek.
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            <b>Druhá fáze</b> - úklid. Vše ze zadního prostoru muselo pryč. Sedačky, pásy,
                            plastové díly. Nakonec i podlaha, to, co bylo pod ní, a také stropní deska.
                            Prostor jsme poté vymydlili do bíla.
                        </p>
                    </Row>
                    <Row className="text-center p-4">
                        <Col className="mr-auto ml-auto p-1" md="6">
                            <img
                                alt="..."
                                className="img-rounded img-responsive"
                                src={require("assets/img/tech_1.webp")}
                            />
                        </Col>
                        <Col className="mr-auto ml-auto p-1" md="6">
                            <img
                                alt="..."
                                className="img-rounded img-responsive"
                                src={require("assets/img/tech_2.webp")}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <p className="text-justify font-weight-normal p-2">
                            <b>Třetí fáze</b> - okna. Náš T5 long transportér měl (zvenku pořád má) v zadním
                            prostoru dvě velká okna na obou stranách. Nechtěli jsme, aby do auta bylo
                            vidět a zároveň bylo jasné, že zadní okna budeme zevnitř zadělávat.
                            Neprůhledné tmavé folie byly tak jasná volba. Zvládnuto to bylo za tři hoďky
                            a necelých šest tisíc v jedné garáži poblíž Prahy. Kluky z Běchovic můžeme
                            jen doporučit!
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            <b>Čtvrtá fáze</b> - podlaha. Po sedačkách v zadním prostoru zůstaly kovové ližiny.
                            Příliš se nám do jejich odšroubování nechtělo, proto jsme zvolili jinou
                            cestu. Tohle udělejte jinak a dejte si tu práci s odstraněním. My jsme
                            složitě do OSB desky a izolace vyřezávali otvory, abychom ližiny zarovnali s
                            podlahou. Bylo to pracné a i po zadělání děr izolační pěnou jsou pod
                            vinylovou podlahou místa cítit. Ale zpět na začátek. Na podlahu jsme
                            vyrobili rošt z latěk, do vzniklých otvorů položili K-flex. Ten jako
                            izolační materiál můžeme doporučit - dobře se s ním pracovalo a auto je
                            odizolované perfektně. Jeho nevýhodou je vyšší cena. Na izolaci přišla OSB
                            deska.
                        </p>
                    </Row>
                    <Row className="text-center p-4">
                        <Col className="mr-auto ml-auto p-1" md="6">
                            <img
                                alt="..."
                                className="img-rounded img-responsive"
                                src={require("assets/img/tech_3.webp")}
                            />
                        </Col>
                        <Col className="mr-auto ml-auto p-1" md="6">
                            <img
                                alt="..."
                                className="img-rounded img-responsive"
                                src={require("assets/img/tech_4.webp")}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <p className="text-justify font-weight-normal p-2">
                            <b>Pátá fáze</b> - rozvody a izolace stěn a stropu. Rozvody dělal Jakub - samozvaný
                            autoelektikář - úplně sám. Tady návody na youtubu zafungovaly skvěle! Z
                            přídavné baterie rozvedl kabely pro dvě zásuvky USB v zadní části a pro
                            kabel od LED světel ve stropě. Po kabeláži, zapojení pojistek a měniče
                            napětí pro jednu 220V zásuvku jsme se vrhli na izolaci stěn a stropu. Do
                            členitých boků jsme nacpali minerální vatu a na strop nalepili opět K-flex.
                        </p>
                    </Row>
                    <Row className="text-center p-4">
                        <Col className="mr-auto ml-auto p-1" md="4">
                            <img
                                alt="..."
                                className="img-rounded img-responsive"
                                src={require("assets/img/tech_5.webp")}
                            />
                        </Col>
                        <Col className="mr-auto ml-auto p-1" md="4">
                            <img
                                alt="..."
                                className="img-rounded img-responsive"
                                src={require("assets/img/tech_6.webp")}
                            />
                        </Col>
                        <Col className="mr-auto ml-auto p-1" md="4">
                            <img
                                alt="..."
                                className="img-rounded img-responsive"
                                src={require("assets/img/tech_7.webp")}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <p className="text-justify font-weight-normal p-2">
                            Mezi všemi těmito pracemi probíhalo v tátově dílně sestavení vestavby.
                            Přesně naměřené spárovky se pomocí spojovacích kolíků, šroubků a vrtačky
                            sešroubovaly do jedné konstrukce. Prozatím bez vrchních desek, spodek
                            nakonec vestavba nemá vůbec - nebyl třeba.
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            <b>Šestá fáze</b> - co na stěny? Jestli je něco, co už bychom rozhodně nikdy
                            neudělali tak, jak jsme to udělali, je to obklad stěn. Dlouho jsme
                            pátrali po tom, jak stěny obložit. Nechtěli jsme natloukat hipsterské
                            palubky ani lepit dědečkovský zátěžák. A tak jsme zvolili vlastní
                            způsob. Bílý sololit! Je levný, ohebný, dobře se řeže, bude obstojně
                            vypadat a něco málo z něj i umyjete. Chyba! Sololit byl opravdu levný
                            (tedy, když jsme jeli pro třetí várku, už jsme o tom dost pochybovali),
                            ohebný taky (ale pořád málo, aby na některých místech opravdu přilnul ke
                            křivkám našeho Bydlíka) ale vůbec, vůbec se dobře neřezal! Když už jsme
                            po mnoha a mnoha pokusech přišli na to, jak dělat rovný řez, a už už to
                            skoro měli - vždycky nám ujela ruka a do bílé plochy se nemilosrdně
                            zařízlo ostří kobercového nože (a ne, nešel řezat z rubové hnědé strany,
                            ta se drolila). Největší potíž celé obkládky však byla ta, že se nám
                            sololitové desky nedařily oříznout tak, aby do prostoru dobře sedly. A
                            tak jsme se opravdu hodně natrápili měřením, obkreslováním na kartony,
                            řezáním a zkoušením. Výsledek není dokonalý. Ale je!
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            <b>Sedmá fáze</b> - podlaha. Tato fáze nebyla nijak zajímavá. Koupili jsme
                            vinylové pruhy a ty cik cak nalepili na OSB desku. Nedělejte to. Je to
                            celkem rychlé a snadné, dobře to vypadá, ale lepidlo na plátech není
                            pevné a teplo v autě nakonec podlahu zvedlo. Podlaha je tak lepená
                            nadvakrát - podruhé mamutem. Jak ale není zalištovaná, její pruhy si
                            stejně dělají, co chtějí. A tak bychom příště volili vinyl vcelku nebo
                            nějaké lino.
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            <b>Osmá fáze</b> - strop. Tato fáze naopak velmi zajímavá byla. Co chceme na
                            strop, jsme totiž nevěděli, ani když jsme pro tento materiál jeli.
                            Věděli jsme, co ale už nikdy nechceme - sololit. A v obchodě se stal
                            zázrak! V tom stejném hobbymarketu, kam jsem během pár týdnu přestavby
                            zamířili tak asi potisícáté, jsme objevili poklad! Obkladový PVC panel.
                            Nevíme, jakto, že jsme jej přes ten všechen sololit dříve nezahlédli,
                            ale byl tu. V mnoha a mnoha krásných motivech, velké pevné, ale přesto
                            lehké desky. A tak máme strop v podobném dekoru jako podlahu a vyřezán
                            byl za pár minutek (tedy i proto, že strop měl na rozdíl od stěn původní
                            desku, kterou jsme použili jako šablonu). A protože tloušťka byla pár
                            milimetrů, přesně jako u původní stropní desky, mohli jsme jej přidělat
                            i původními špuntíky do původních dírek. Jak snadné!
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            <b>Devátá fáze</b> - konstrukce kuchyně. Tahle fáze byla jedna z nejtěžších.
                            Sestavit a vymyslet kuchyňskou linku dalo rodičům pěkně zabrat. Nakonec
                            se to ale podařilo. Linka je rozdělená na dvě poloviny - v té levé je
                            lednička otevíraná shora (zn. Domestic) a v té pravé dřez (jeho sehnání
                            byl také běh na dlouhou trať, neboť takhle malý dřez abyste pohledali -
                            my nakonec pohledali na internetu). Ve spodní části je malý špajz, dva
                            kanystry (pitná a odpadní voda) a poličky na nádobí. Linka jde do půlky
                            vysunout ven a prodloužit o kus pracovní plochy. A tak uvaříme a i
                            nádobí umyjeme, aniž bychom celý proces absolvovali v kleku nebo s boulí
                            na hlavě.
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            <b>Desátá fáze</b> - kompletace vestavby. Vestavba v zadní části nakonec
                            dostala i vrchní OSB desky (zde jsem použili ty pevnější s tloušťkou 2,8
                            cm, na rozdíl od boků, kde nám postačila levnější 1,8 cm). Protože celá
                            vestavba slouží ke spaní, panty jsme přidělali ze shora, aby se pod
                            tíhou spáče neohnuly (ještě jednou díky za nápad, mami!). Dál už zbývalo
                            jen vymyslet, jak udělat, aby stůl udržel talíř a notebook (zde vzdáváme
                            hold obyčejným háčkům, které dříve nahrazovaly okenní větračky) a
                            prodloužení jednoho dílu postele (viva la Ikeácké nápady, jak sestavit
                            všemožné!). Vyspí se u nás stodevadesáticentimetrový dlouhán a v pohodě
                            si i bez klopení hlavy sedne ke stolu! Úložných prostorů vzniklo v
                            posteli nakonec víc, než jsme čekali a doufali. Jedna strana tak slouží
                            jako zásobárna kuchyňského harampádí, co se do linky nevešlo, vinný a
                            pivní sklípek a i spacák pro zimomřivé se vejde. Druhá velká část
                            poslouží jako šatna. Malá komůrka se našla i pro koupelnové potřeby a do
                            jednoho ze šuplíků jsme uložili i peřiny. A můžeme(te) vyrazit!
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            Fází přestavby bylo samozřejmě mnohem, mnohem více. Stejně tak jako
                            potíží, které jsme řešili. Zde je jen základních deset, které nám
                            zabraly nejvíce času. Dále jsme museli řešit například, co s komínkem od
                            topení, který ale nefunguje (nakonec tam zůstal), jak a čím přilepit led
                            světla (vyšlo to až na potřetí, díky Mamute!), jak zalištovat okno
                            (nakonec jsme to nějak stloukli) a také čím a jak zakrýt zadní okna (pod
                            sololitem je nakonec polystyrén spolu s minerální vatou).
                        </p>
                    </Row>
                    <Row>
                        <h2>První velká cesta s odtahovkou</h2>
                    </Row>
                    <Row>
                        <p className="text-justify font-weight-normal p-2">
                            S Bydlíkem už jsme pár tisíc kilometrů najezdili. I když jeho první
                            velká cesta v létě 2020 nakonec skončila po pár stovkách kilometrů.
                            Vzpomínáte na ty automechaniky z odstavců výše? Tak tihleti chlapíci
                            technický stav auta přechválili a po překročení česko-rakouských hranic
                            se autu zadřel motor. S ním odešla do věčných lovišť i převodovka a
                            oprava si tak vyžádala nejen velkou sumu peněz, ale také spoustu času. Z
                            první velké cesty tak Bydlík zpátky domů dojel na odtahu. Naštěstí se
                            mu, krom technické závady, nezkřivil ani jeden sololit! Ze servisu se
                            ovšem dostal až na podzim.
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            Vší parádu jsme tak pořádně vyzkoušeli až v chladnějších měsících, ovšem
                            díky nezávislému topení to jsou výjezdy stále více než příjemné! Navíc,
                            po technické stránce je nyní auto perfektně zkontrolované a taky pod
                            pečlivým dohledem servisu, kde ho nyní, chtě nechtě, mají už skoro jako
                            vlastní!
                        </p>

                    </Row>
                    <Row>
                        <h2>Dobrodružství na čtyřech kolech</h2>
                    </Row>
                    <Row>
                        <p className="text-justify font-weight-normal p-2">
                            Nyní už vše funguje. Vestavba je funkční, do auta se vejde vše, co
                            potřebujete. Z postele je stůl se sezením za pár minutek, zvládne to
                            každý. V autě si nabijete telefony, notebooky, rozsvítíte, zatopíte. Do
                            auta za bílého dne není vidět, můžete si tak užívat soukromí. V noci
                            dejte na zvědavá oka pozor, když máte rozsvíceno, záclonky jsou teprva v
                            řešení. Spaní je díky komfortní matraci nerozeznatelné od vlastní
                            postele. Se soběstačností je to také fajn. Jednou za dva až tři dny je potřeba
                            dobít v kempu baterka, pokud tedy každý den neurazíte několik desítek
                            kilometrů, to se pak zvládne dobít sama. Ve dřezu teče
                            voda, 20l kanystr vám na nějaké to vaření a opláchnutí nádobí na chvíli
                            vystačí.
                        </p>
                        <p className="text-justify font-weight-normal p-2">
                            Auto jezdí slušně, zvládne dálnice, ale i městské uličky. Daří se mu i v
                            terénu, jen vestavba občas zaskřípe, hlavně tedy na nerovné či děravé
                            silnici. Spotřeba je v průměru 7 litrů, zvedá se jen při šplhání do
                            tisícových vrcholků hor.
                        </p>
                    </Row>
                    <br/>
                    <Row className="p-2">
                        <h6>Nejvíce ale poznáte, když si Bydlíka půjčíte. Vlastní dobrodružství na
                            čtyřech kolech čeká!</h6>
                    </Row>
                    {/*<br />*/}
                    {/*<hr />*/}
                    {/*<Row>*/}
                    {/*    <Media>*/}
                    {/*        <a*/}
                    {/*            className="pull-left"*/}
                    {/*            href="#dominika"*/}
                    {/*            onClick={(e) => e.preventDefault()}*/}
                    {/*        >*/}
                    {/*            <div className="avatar big-avatar">*/}
                    {/*                <Media*/}
                    {/*                    alt="..."*/}
                    {/*                    object*/}
                    {/*                    src={require("assets/img/dominika.jpg")}*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        </a>*/}
                    {/*        <Media body>*/}
                    {/*            <Media heading>Dominika</Media>*/}
                    {/*            <h6 className="text-muted">28.1.2021</h6>*/}
                    {/*        </Media>*/}
                    {/*    </Media>*/}
                    {/*</Row>*/}
                </Container>
            </div>
        </>
      <DemoFooter />
    </>
  );
}

export default JakToByloPage;
