/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

import { HOPPY_GO } from '../../consts'


// core components

function SectionDownload() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Zažij vlastní dobrodružství</h2>
              <p className="font-weight-normal">
                Bydlík je především auto. Má čtyři kola, bílou kapotu a hrdě nosí znak Volswagenu. Avšak uvnitř skrývá něco navíc. Všechno, co na svých cestách potřebuješ.
              </p>
            </Col>
          </Row>
          <br/>
          <Row className="align-items-center" >
            <Col md="3">
              <img
                  alt="..."
                  className="img-rounded img-responsive p-1"
                  src={require("assets/img/rent-1.jpg")}
              />
            </Col>
            <Col md="3">
              <img
                  alt="..."
                  className="img-rounded img-responsive p-1"
                  src={require("assets/img/rent-2.jpg")}
              />
            </Col>
            <Col md="3">
              <img
                  alt="..."
                  className="img-rounded img-responsive p-1"
                  src={require("assets/img/rent-3.jpg")}
              />
            </Col>
            <Col md="3">
              <img
                  alt="..."
                  className="img-rounded img-responsive p-1"
                  src={require("assets/img/rent-4.jpg")}
              />
            </Col>
          </Row>
          <br/>
          <Row className="text-center">
            <Col className="ml-auto mr-auto" md="8">
              <p className="font-weight-normal">
                Pokud si chceš Bydlíka půjčit, klikni na tlačítko níže.
                Stačí napsat a zarezervovat si termín na dominika.balatkova@gmail.com.
              </p>
            </Col>
            <Col className="ml-auto mr-auto download-area" md="5">
              <Button
                  className="btn-round"
                  color="danger"
                  href={HOPPY_GO}
                  target="_blank"
              >
                Zarezervovat termín
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionDownload;
