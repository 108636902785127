/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components

// core components
import DemoFooter from "components/Footers/DemoFooter.js";
import IndexNavbar from '../../components/Navbars/IndexNavbar';
import { Col, Container, Row } from 'reactstrap';
import ArticleHeader from './ArticleHeader';

function JakToByloPage() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <IndexNavbar />
            <ArticleHeader backgroundImage={"url(" + require("assets/img/blog1.JPG") + ")"} />
            <>
                <div className="section section-white">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center title" md="6">
                                <h1>Výlet do Krásna</h1>
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Koncem října na nás čekal v kalendáři volný a zdálo se, že i slunný víkend. Venku stále panovala pandemie, a tak byl náš cíl jasný: příroda a co nejméně lidí. Vyrazili jsme v sobotu dopoledne za slibným cílem: Křivoklátsko. Hrad už jsme chtěli vidět dlouho. Malovali jsme si krásnou sobotní procházku či menší túru na hrad a přespání někde v křivoklátských lesích.</p>
                            <p className="text-justify font-weight-normal p-2">Avšak ty naše cesty většinou nemá vůbec smysl plánovat. Křivoklátské parkoviště bylo hodně blízko hradu a ten, stojíc hned u silnice, zavřený. I proto jsme nechápali, kde se tam vzalo tolik lidí. Zklamaně jsme zjistili, že kolem hradu se moc jít nedá a stezka do lesů v nedohlednu. Tak jsme alespoň vytáhli drona a Kuba zeshora pořídil krásné záběry tohohle původně gotického hradu.</p>
                        </Row>
                        <Row>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/01-01.JPG")}
                                />
                            </Col>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/01-02.JPG")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">V jednu hodinu po poledni jsme sedli do auta na parkovišti před místní Jednotou, která nyní fungovala spíše jako bar pro místní pivaře a rozmýšleli, co dál. </p>
                            <p className="text-justify font-weight-normal p-2">A protože Kuba vždycky startuje dříve, než víme, kam jedeme, ocitli jsme se na výpadovce směr západní Čechy. No, proč ne. V Mariánkách jsme byli loni a v Karlových Varech nikdy. Ale byl moc pěkný den na to, strávit ho uprostřed města. Google mapy ukazovaly zelenou v oblasti se jménem Kladská. Vzpomněli jsme si, že jsme slyšeli o rašeliništích, která na kladské jsou.</p>
                            <p className="text-justify font-weight-normal p-2">Po necelé hodince jsme byli na místě. Hned za první velkou restaurací jsme zaparkovali Bydlíka a jali se obědvat. Z domu jsme měli vše připravené, a tak to byla otázka pár minut. K dokonalému sobotnímu obědu přispěla i polévka z okénka restaurace, u níž jsme parkovali. Jen těch lidí, zase! Ale co, najíme se a vyrazíme do rašelinišť, však tam se tolik výletů chtivých lidí rozptýlí.</p>
                        </Row>
                        <Row>
                            <Col className="ml-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/02-01.jpg")}
                                />
                            </Col>
                            <Col className="mr-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/02-02.jpg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Rašeliniště nás překvapila! Jednak jsou opravdu krásná a jednak jsme je měli za hodinu projité. A to počítám i frontu na toaletu a kafe ve vedlejší restauraci na konci stezky. Ale i tak tam stojí za to vyrazit. Všechny ty barvy modré a zelené jsou uklidňující, na naučných cedulích se dozvíte mnohé z historie Kladska a když budete mít štěstí, můžete zahlédnout tetřeva hlušce nebo čápa černého. Na místní faunu jsme sice štěstí neměli, ale bažincem zarostlý rybníček nás zaujal o to víc.</p>
                        </Row>
                        <Row>
                            <Col className="mr-auto ml-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/03-01.jpg")}
                                />
                            </Col>
                            <Col className="mr-auto ml-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/03-02.jpg")}
                                />
                            </Col>
                            <Col className="mr-auto ml-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/04-01.jpg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Po návratu k autu nás čekal úkol dne: najít místo na spaní. To je pro nás vždycky hodně náročný úkol, proto s tím začínáme v dostatečném předstihu. Mnohdy i několik dní předem. Abyste tomu dobře rozuměli, nejsme nijak nároční. Chceme tam jen být sami, aby nás nikdo nerušil, ale zase ne nikde uprostřed lesa, to bychom se báli (teda Domča). Kousek od vody, protože, co kdyby nás ráno popadly otužilecké choutky (Domču). S výhledem do krajiny, protože kdo nemá fotku nohou z kufru, není žádný vanlifer (a taky ještě žádnou nemáme). No, prostě je to výzva. Občas nám pomáhá appka Park for night. Většinou ale objedeme čtyři až pět míst, než se nám (Domče) nějaké zalíbí.</p>
                            <p className="text-justify font-weight-normal p-2">Tentokrát nás kola (a appka) zavezla do malebné vesničky Krásno. A to vám bylo místo! U rybníčka, na krásné louce, kousek od lesa, jen už tam někdo, respektivy tři rodinky karavanistů se spoustu ratolestí, byl. Ale nevzdali jsme to! Objeli jsme je a pokračovali po celkem slušné lesní cestě kolem pole a pak k lesu. A hele! Krásné místo i s ohništěm, nikde nikdo a výhled impozantní. Z jedné strany do lesa, že jsme začali doufat, že nám lišky přijdou dát dobrou noc a srnky dobré ráno, a z druhé strany louka se stromy barvící se do podzimní palety všech odstínů oranžové. Nádhera!</p>
                        </Row>
                        <Row className="text-center p-4">
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/05-01.JPG")}
                                />
                            </Col>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/05-02.JPG")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mr-auto ml-auto p-1" md="6">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/05-03.JPG")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Ale nebyl čas ztrácet čas. Ještě bylo brzo na to uložit se do hajan, ale do setmění také už moc nezbývalo. Rychlým pohledem do mapy jsme zjistili, že necelé čtyři kilometry daleko nabízí Krásno prý moc krásnou rozhlednu. A tak jsme vyrazili. A ono opravdu. Dorazili jsme na ní až s večenicí na obloze, ale možná právě proto bylo toto místo působivé. Malá kamenná rozhledna na kopečku tyčícím se hned za vesnicí. </p>
                        </Row>
                        <Row className="text-center p-4">
                            <Col className="mr-auto ml-auto p-1" md="12">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/06-01.jpg")}
                                />
                            </Col>
                            </Row>
                            <Row>
                            <Col className="ml-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/06-03.jpg")}
                                />
                            </Col>
                            <Col className="mr-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/06-04.jpg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Cestou dolů už byla opravdu tma. Nebe bylo jasné a vesnička pod námi setmělá. A tak jsme si pomocí další aplikace hráli na hvězdáře. To je mimochodem naše oblíbená činnost při spaní někde v přírodě. Tolik hvězd, planet a satelitů, to z pražského bytu totiž jen tak nevidíme.</p>
                        </Row>
                        <Row className="text-center p-4">
                            <Col className="mr-auto ml-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/07-01.jpg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Druhý den ráno nás vzbudilo slunce a nádherné ráno. Krom pár projíždějících cyklistů jsme na našem spotu byli opravdu sami. Snídaně nám (jako vždycky) zabrala celé dopoledne. Objevili jsme krásy místa ze vzduchu a chvíli pozorovali les, zda nás nepřijdou pozdravit ty srnky. Nepřišly. I tak ale bylo to ráno klidné, plné vůně lesa, čerstvé kávy, podzimního slunce a barevných stromů, prostě nádherné.</p>
                        </Row>
                        <Row>
                            <Col className="mr-auto ml-auto p-1" md="4">
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive"
                                    src={require("assets/img/blog1/08-01.jpg")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-justify font-weight-normal p-2">Po snídani jsme vyrazili do Karlových Varů. Ty nás překvapily prázdnými ulicemi, spoustou otevřených okýnek s oplatkami, kávou a obědovou nabídkou. Vyzkoušeli jsme tak všechno z výše jmenovaného a nakonec jsme, abychom také nějakou energii vydali, vyšplhali na rozhlednu Dianu. Po cestě kopcovitým terénem se setkáte s naučnými cedulemi o zvířatech, která v místě žijí. A budete-li mít štěstí jako my, potkáte i ta zvířátka. Z divokého prasete, které nás svým funěním v přilehlém křoví vyděsilo k smrti, se nakonec vyklubalo stádo mini prasátek. Na vršku je krom rozhledny totiž také minizoo, avšak jeho oplocení již dávno neslouží svému účelu. A tak se mini prasátka, která se do děr v plotě vejdou, potulují po Výšině přátelství, jak se jim zlíbí.</p>
                            <p className="text-justify font-weight-normal p-2">Křivoklát, Kladskou, Krásno i Karlovy Vary můžeme jen doporučit. A pokud si Bydlíka půjčíte a budete chtít zamířit na západ, slibujeme, že Vám k autu přidáme i souřadnice k tomu krásnému místu na spaní.</p>
                        </Row>
                        {/*<br />*/}
                        {/*<hr />*/}
                        {/*<Row>*/}
                        {/*    <Media>*/}
                        {/*        <a*/}
                        {/*            className="pull-left"*/}
                        {/*            href="#dominika"*/}
                        {/*            onClick={(e) => e.preventDefault()}*/}
                        {/*        >*/}
                        {/*            <div className="avatar big-avatar">*/}
                        {/*                <Media*/}
                        {/*                    alt="..."*/}
                        {/*                    object*/}
                        {/*                    src={require("assets/img/dominika.jpg")}*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*        </a>*/}
                        {/*        <Media body>*/}
                        {/*            <Media heading>Dominika</Media>*/}
                        {/*            <h6 className="text-muted">7.2.2021</h6>*/}
                        {/*        </Media>*/}
                        {/*    </Media>*/}
                        {/*</Row>*/}
                    </Container>
                </div>
            </>
            <DemoFooter />
        </>
    );
}

export default JakToByloPage;
