/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

import { HOPPY_GO } from '../../consts'


// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/chorvatsko.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
            <Container>
                <div className="motto text-center">
                    <h1 className="presentation-title">Bydlík.</h1>
                    <h1 className="presentation-title">The Van.</h1>
                    <h3 className="presentation-subtitle text-center">
                        <b>Bydlík hledá nové majitele!</b> <Button  href="/inzerat" color="danger">Prohlédnout Inzerát</Button>
                    </h3>
                </div>
            </Container>
        </div>
        {/*<div*/}
        {/*  className="moving-clouds"*/}
        {/*  style={{*/}
        {/*    backgroundImage: "url(" + require("assets/img/clouds.png") + ")",*/}
        {/*  }}*/}
        {/*/>*/}
      </div>
    </>
  );
}

export default IndexHeader;
