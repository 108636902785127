/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

// index sections
import SectionDownload from "views/index-sections/SectionDownload.js";
import SectionBlog from "views/index-sections/SectionBlog";
import { Col, Container, Row } from 'reactstrap';

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      {/*<Alert color="danger" className="mb-0">*/}
      {/*  <Row className="align-items-center">*/}
      {/*    <Col className="mr-auto text-center" md="6">*/}
      {/*      <b>Bydlík hledá nové majitele!</b>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</Alert>*/}
      <IndexNavbar />
      <IndexHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row className="align-items-center" >
              <Col md="6">
                <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("assets/img/oba.webp")}
                />
              </Col>
              <Col md="6">
                <h5 className="font-weight-normal p-1">
                  Jsme Jakub a Dominika. Máme rádi cestování, poznávání nových míst a tak nějak vůbec ze všeho nejraději zkoušíme úplně nové věci.
                  Jednou jsme se společně vrhli i na přestavbu obytné dodávky. Bydlíka. Brázdí s námi Čechy i Evropu a připravil nám už nejedno dobrodružství.
                </h5>
              </Col>
            </Row>
              <Row className="align-items-center" >
              <Col md="6">
                <h5 className="font-weight-normal p-1">
                A protože na čtyřech kolech může svět objet každý, můžeš si bydlíka půjčit i Ty.
                </h5>
              </Col>
                <Col md="6">
                  <img
                      alt="..."
                      className="img-rounded img-responsive"
                      src={require("assets/img/logo-vw.webp")}
                  />
                </Col>
            </Row>
          </Container>
        </div>
        <SectionBlog />
        {/*<SectionCarousel />*/}
        <SectionDownload />
        {/*<SectionButtons />*/}
        {/*<SectionNavbars />*/}
        {/*<SectionNavigation />*/}
        {/*<SectionProgress />*/}
        {/*<SectionNotifications />*/}
        {/*<SectionTypography />*/}
        {/*<SectionJavaScript />*/}
        {/*<SectionNucleoIcons />*/}
        {/*<SectionDark />*/}
        {/*<SectionLogin />*/}
        {/*<SectionExamples />*/}
        {/*<SectionDownload />*/}
        <DemoFooter />
      </div>
    </>
  );
}

export default Index;
