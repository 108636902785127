import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionBlog() {
  return (
    <>
      <div className="section secion-blog cd-section section-gray" id="blogs">
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="title text-center">Na cestách</h2>
            <br />
          </Col>
        </Row>
        <div className="article">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <Card className="card-blog card-plain text-center">
                <div className="card-image">
                  <a href="/krasno" onClick={(e) => e.preventDefault()}>
                    <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/blog1.JPG")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="/krasno">
                    <CardTitle tag="h3">
                      Výlet do Krásna
                    </CardTitle>
                  </a>
                  <div className="card-description">
                    <p>
                      Koncem října na nás čekal v kalendáři volný a zdálo se, že i slunný víkend. Venku stále panovala pandemie, a tak byl náš cíl jasný: příroda a co nejméně lidí. Vyrazili jsme v sobotu dopoledne za slibným cílem: Křivoklátsko. Hrad už jsme chtěli vidět dlouho. Malovali jsme si krásnou sobotní procházku či menší túru na hrad a přespání někde v křivoklátských lesích.
                    </p>
                  </div>
                </CardBody>
                <Button className="btn-round" color="danger" size="sm" href="/krasno" >
                  Celý článek
                </Button>
              </Card>
            </Col>
          </Row>
        </div>
        <Row className="p-5"></Row>
        <div className="article">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <Card className="card-blog card-plain text-center">
                <div className="card-image">
                  <a href="/toulky-po-istrii" onClick={(e) => e.preventDefault()}>
                    <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/istrie/cover.jpeg")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="/toulky-po-istrii">
                    <CardTitle tag="h3">
                      Toulky po Istrii
                    </CardTitle>
                  </a>
                  <div className="card-description">
                    <p>
                      Koncem října nám do klína spadla další možnost vyrazit do světa. Zpomalení zemských otáček nám oběma přineslo home office a vyhlídku do dalších šedivých a deštivých pražských dnů. Naložili jsme s tím po svém, sbalili sebe i Bydlíka a vyrazili na cestu po chorvatské Istrii. Následující řádky vás provedou nejkrásnějšími místy, které jsme za tři týdny potulky objevily. Nechť vám jsou inspirací, až pojedete na chorvatský sever.
                    </p>
                  </div>
                </CardBody>
                <Button className="btn-round" color="danger" size="sm" href="/toulky-po-istrii" >
                  Celý článek
                </Button>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default SectionBlog;
